/* eslint-disable react/jsx-filename-extension */
import React, {useEffect} from 'react';
import LoginPage from '@macanta/modules/pages/LoginPage';
import DashboardPage from '@macanta/modules/pages/DashboardPage';
import BaseTheme from '@macanta/modules/BaseTheme';
import {navigate} from 'gatsby';
import {isLoggedIn} from '@macanta/utils/app';

const IndexPage = () => {
  useEffect(() => {
    if (isLoggedIn()) {
      navigate('/app/dashboard');
    }
  }, []);

  return (
    <BaseTheme>{!isLoggedIn() ? <LoginPage /> : <DashboardPage />}</BaseTheme>
  );
};

export default IndexPage;
